$(function() {
    let url = location.href;
    let url_array = url.split("/");
    let uri = url_array[url_array.length-1];
    if(url_array[url_array.length-2] != 'undefined') {
        if(url_array[url_array.length-2] == 'token') {
            uri = url_array[url_array.length-2];
        }
    }
    if(uri != 'login' && uri != 'token') {
        if (Boolean(sessionStorage.getItem('sidebar-toggle-collapsed'))) {
            var body = document.getElementsByTagName('body')[0];
            body.className = body.className + ' sidebar-collapse';
        }

        $('.sidebar-toggle').click(function(event) {
          event.preventDefault();
          if (Boolean(sessionStorage.getItem('sidebar-toggle-collapsed'))) {
            sessionStorage.setItem('sidebar-toggle-collapsed', '');
          } else {
            sessionStorage.setItem('sidebar-toggle-collapsed', '1');
          }
        });
    }

    // Перевод datatables лежит в /locales/datatable_lang.json
    switch (window.lang) {
        case "en":
            window.dataTableLang = {
                "nothingfound": "Nothing found",
                "sEmptyTable": "No data available",
                "sInfo": "Entries from _START_ to _END_, total _TOTAL_",
                "sInfoEmpty": "Entries from 0 to 0, total 0",
                "sInfoFiltered": "(filtered from _MAX_ total entries)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "_MENU_ Show entries",
                "sLoadingRecords": "Loading...",
                "sProcessing": "Processing...",
                "sSearch": "Search:",
                "sZeroRecords": "No matching records found",
                "oPaginate": {
                    "sFirst": "First",
                    "sLast": "Last",
                    "sNext": "Next",
                    "sPrevious": "Previous"
                },
                "oAria": {
                    "sSortAscending": ": activate to sort column ascending",
                    "sSortDescending": ": activate to sort column descending"
                }
            };
            break;
        case "ru":
            window.dataTableLang = {
                "nothingfound": "Ничего не найдено",
                "sProcessing":   "Подождите...",
                "sLengthMenu":   "_MENU_ Показано записей",
                "sZeroRecords":  "Записи отсутствуют.",
                "sInfo":         "Записи от _START_ до _END_, всего _TOTAL_",
                "sInfoEmpty":    "Записи от 0 до 0, всего 0",
                "sInfoFiltered": "(отфильтровано из _MAX_ записей)",
                "sInfoPostFix":  "",
                "sSearch":       "Поиск:",
                "sUrl":          "",
                "oPaginate": {
                    "sFirst": "Первая",
                    "sPrevious": "Предыдущая",
                    "sNext": "Следующая",
                    "sLast": "Последняя"
                },
                "oAria": {
                    "sSortAscending":  ": активировать для сортировки столбца по возрастанию",
                    "sSortDescending": ": активировать для сортировки столбцов по убыванию"
                }
            };
            break;
        case "uk":
            window.dataTableLang = {
                "nothingfound": "Нічого не знайдено",
                "sEmptyTable":     "Немає даних у таблиці",
                "sInfo":           "Записи від _START_ до _END_, всього _TOTAL_",
                "sInfoEmpty":      "Записи від 0 до 0, всього 0",
                "sInfoFiltered":   "(відфільтровано з _MAX_ загальної кількості записів)",
                "sInfoPostFix":    "",
                "sInfoThousands":  ",",
                "sLengthMenu":     "_MENU_ Показати записи",
                "sLoadingRecords": "Завантаження...",
                "sProcessing":     "Обробка...",
                "sSearch":         "Пошук:",
                "sZeroRecords":    "Не знайдено відповідних записів",
                "oPaginate": {
                    "sFirst":    "Перший",
                    "sLast":     "Остання",
                    "sNext":     "Далі",
                    "sPrevious": "Попередня"
                },
                "oAria": {
                    "sSortAscending":  ": активувати, щоб сортувати стовпець у порядку зростання",
                    "sSortDescending": ": активувати, щоб сортувати стовпець знижуючи"
                }
            };
            break;
        case "hr":
            window.dataTableLang = {
                "nothingfound": "Ništa nije pronađeno",
                "sEmptyTable": "Podaci u tablici nisu dostupni",
                "sInfo": "Prikazuje se _START_ do _END_, ukupno _TOTAL_",
                "sInfoEmpty": "Prikazuje se 0 do 0, ukupno 0",
                "sInfoFiltered": "(filtrirano od _MAX_ ukupnih unosa)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "_MENU_ Prikaži unose",
                "sLoadingRecords": "Učitavam...",
                "sProcessing": "Obrada...",
                "sSearch": "Traži:",
                "sZeroRecords": "Nije pronađen nijedan podudarni zapis",
                "oPaginate": {
                    "sFirst": "Prvi",
                    "sLast": "Posljednji",
                    "sNext": "Sljedeće",
                    "sPrevious": "Prethodno"
                },
                "oAria": {
                    "sSortAscending": ": aktivirati za sortiranje stupca uzlazno",
                    "sSortDescending": ": aktivirajte za sortiranje stupaca prema silaznom nizu"
                }
            };
            break;
        default:
            window.dataTableLang = {
                "nothingfound": "Ничего не найдено",
                "sProcessing":   "Подождите...",
                "sLengthMenu":   "_MENU_ Показано записей",
                "sZeroRecords":  "Записи отсутствуют.",
                "sInfo":         "Записи от _START_ до _END_, всего _TOTAL_",
                "sInfoEmpty":    "Записи от 0 до 0, всего 0",
                "sInfoFiltered": "(отфильтровано из _MAX_ записей)",
                "sInfoPostFix":  "",
                "sSearch":       "Поиск:",
                "sUrl":          "",
                "oPaginate": {
                    "sFirst": "Первая",
                    "sPrevious": "Предыдущая",
                    "sNext": "Следующая",
                    "sLast": "Последняя"
                },
                "oAria": {
                    "sSortAscending":  ": активировать для сортировки столбца по возрастанию",
                    "sSortDescending": ": активировать для сортировки столбцов по убыванию"
                }
            };
    }

    $('.js-select2').select2({
        allowClear: true,
        placeholder: "Select an option",
        language: {
            "noResults": function(){
                return window.dataTableLang.nothingfound;
            }
        }
    });
    $('.mselect2').selectpicker({ style: 'btn-info', size: 4 });

        setTimeout(function() {
            $('.points').remove();
        }, 1500);

    $(document).on("change", ".showHide", function(e) {
        var status = $(this).is(':checked');
        console.log(status);
        if (status) {
            $(".hides." + $(this).data('show')).addClass("active");
            $(".hides." + $(this).data('show')).find("input[type='text'], input[type='password']").each(function() {
                $(this).attr('name', $(this).data('name'));
            });
        } else {
            $(".hides." + $(this).data('show')).removeClass("active");
            $(".hides." + $(this).data('show')).find("input[type='text'], input[type='password']").each(function() {
                $(this).attr('name', "");
            });
        }
    });

    $(document).on('click', '.modal_add', function() {
        clear_modal();
        var action = $(this).attr('action');
        $.ajax({
            async: false,
            url: action + '/form_add',
            type: 'GET',
            data: { '_token': $('meta[name="csrf-token"]').attr('content') },
            error: function(xhr, status, hz) {
                $('#modal-default .modal-body').html('При загрузке произошла ошибка:' + xhr.responseJSON.status + ' ' + xhr.responseJSON.statusText);
            },
            success: function(data) {
                var obj = jQuery.parseJSON(data);
                if (obj.caption) {
                    $('#modal-default .modal-title').text(obj.caption);
                }
                if (obj.text) {
                    $('#modal-default .modal-body').html(obj.text);
                }
                $('#modal-default .modal-body form').bind('submit', function() {
                    send_add(this);
                    return false;
                });
            }
        });
    });

    $(document).on('click', '.modal_edit', function() {
        clear_modal();
        var action = $(this).attr('action');
        var item = $(this).attr('data-item');
        $.ajax({
            async: false,
            url: action + '/form_edit',
            type: 'GET',
            data: { item: item, '_token': $('meta[name="csrf-token"]').attr('content') },
            error: function(xhr, status, hz) {
                $('#modal-default .modal-body').html('При загрузке произошла ошибка:' + xhr.status + ' ' + xhr.statusText);
            },
            success: function(data) {
                var obj = jQuery.parseJSON(data);
                if (obj.caption) {
                    $('#modal-default .modal-title').text(obj.caption);
                }
                if (obj.text) {
                    $('#modal-default .modal-body').html(obj.text);
                }
                $('#modal-default .modal-body form').bind('submit', function() {
                    send_edit(this);
                    return false;
                });
            }
        });
    });

    $(document).on('click', '.modal_closed', function() {
        clear_modal();
        var action = $(this).attr('action');
        var item = $(this).attr('data-item');
        $.ajax({
            async: false,
            url: action + '/form_closed',
            type: 'GET',
            data: { item: item, '_token': $('meta[name="csrf-token"]').attr('content') },
            error: function(xhr, status, hz) {
                $('#modal-default .modal-body').html('При загрузке произошла ошибка:' + xhr.status + ' ' + xhr.statusText);
            },
            success: function(data) {
                var obj = jQuery.parseJSON(data);
                if (obj.caption) {
                    $('#modal-default .modal-title').text(obj.caption);
                }
                if (obj.text) {
                    $('#modal-default .modal-body').html(obj.text);
                }
            }
        });
    });

    /** @type {(key: string, replace: object) => string} */
    __t = function (key, replace = {}) {
        let translation = key.split('.').reduce((t, i) => t ? t[i] : null, window.translations);
        if (!translation) return key;
        for (var placeholder in replace) translation = translation.replace(`:${placeholder}`, replace[placeholder]);
        return translation;
    };

    $(document).on('click', '.modal_template', function() {
        clear_modal();
        var action = $(this).attr('action');
        var item = $(this).attr('data-item');
        var type = $(this).attr('data-type');
        $.ajax({
            async: false,
            url: action + '/form_template',
            type: 'GET',
            data: { item: item, type:type, '_token': $('meta[name="csrf-token"]').attr('content') },
            error: function(xhr, status, hz) {
                $('#modal-default .modal-body').html('При загрузке произошла ошибка:' + xhr.status + ' ' + xhr.statusText);
            },
            success: function(data) {
                var obj = jQuery.parseJSON(data);
                if (obj.caption) {
                    $('#modal-default .modal-title').text(obj.caption);
                }
                if (obj.text) {
                    $('#modal-default .modal-body').html(obj.text);
                }
                $('#modal-default .modal-body form').bind('submit', function() {
                    send_edit(this);
                    return false;
                });
            }
        });
    });

    $(document).on('click', '.modal_view', function() {
        clear_modal();
        var action = $(this).attr('action');
        var item = $(this).attr('data-item');
        $.ajax({
            async: false,
            url: action + '/form_view',
            type: 'GET',
            data: { item: item, '_token': $('meta[name="csrf-token"]').attr('content') },
            error: function(xhr, status, hz) {
                $('#modal-default .modal-body').html('При загрузке произошла ошибка:' + xhr.status + ' ' + xhr.statusText);
            },
            success: function(data) {
                var obj = jQuery.parseJSON(data);
                if (obj.caption) {
                    $('#modal-default .modal-title').text(obj.caption);
                }
                if (obj.text) {
                    $('#modal-default .modal-body').html(obj.text);
                }
                $('#modal-default .modal-body form').bind('submit', function() {
                    send_edit(this);
                    return false;
                });
                $('.select2').select2({
                    language: {
                        "noResults": function(){
                            return window.dataTableLang.nothingfound;
                        }
                    }
                });
            }
        });
    });

    $(document).on('click', '.modal_delete', function() {
        clear_modal();
        var action = $(this).attr('action');
        var item = $(this).attr('data-item');
        $.ajax({
            async: false,
            url: action + '/' + item + '/form_delete',
            type: 'GET',
            data: { item: item, '_token': $('meta[name="csrf-token"]').attr('content') },
            error: function(xhr, status, hz) {
                $('#modal-default .modal-body').html('При загрузке произошла ошибка:' + xhr.responseJSON.status + ' ' + xhr.responseJSON.statusText);
            },
            success: function(data) {
                var obj = jQuery.parseJSON(data);
                if (obj.caption) {
                    $('#modal-default .modal-title').text(obj.caption);
                }
                if (obj.text) {
                    $('#modal-default .modal-body').html(obj.text);
                }
            }
        });
    });

    $('.str_add').click(function() {
        $('.validate-error').remove();
        var formid = $(this).closest('form').attr('id');
        var action = $(this).closest('form').attr('action');
        if ($(this).closest('form').attr('redirect'))
            var redirect = $(this).closest('form').attr('redirect');

        $('#' + formid + ' :input').each(function() {
            $(this).parent().removeClass('has-error');
        });
        var formData = '_token=' + $('meta[name="csrf-token"]').attr('content');
        formData += '&' + $('#' + formid).serialize();
        addLoad();
        $.ajax({
            url: action,
            data: formData,
            type: 'post',
            error: function(xhr, status, errorThrow) {
                if (xhr.status == 422) { // validator exception
                    validate_handler('#' + formid, xhr.responseText, '');
                } else {
                    console.log(xhr);
                    alert(common.save_error + ':' + xhr.responseJSON.status + ' ' + xhr.responseJSON.statusText);
                }

                removeLoad();

                return false;
            },
            success: function(data, status, xhr) {
                if (data == "forbidden") {
                    removeLoad();
                    alert(common.forbidden);
                }
                var obj = (typeof data == 'string') ? jQuery.parseJSON(data) : data;
                if (obj.status == 'success') {
                    if (obj.warning) {
                        removeLoad();
                        $("#modal-default").modal();
                        $('#modal-default .modal-body').html(obj.warning);
                        $('#modal-default .modal-title').html(obj.warning_title);
                    } else {
                        if (redirect)
                            document.location.href = redirect;
                        else
                            document.location.reload();
                    }
                } else if (obj.status == 'load_file') {
                    removeLoad();
                    $("#modal-default").modal();
                    $('#modal-default .modal-body').html(obj.body);
                    $('#modal-default .modal-title').html(obj.title);
                    addLoad();
                } else {
                    removeLoad();
                    alert(data);
                }
            }
        });
    });

    $(document).on('click', '.str_edit', function(e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var action = $(this).attr('data-action');
        $('#ui-dialog').remove();
        var dlg = $('<div id="ui-dialog" title="' + common.editing_a_record + '"></div>').appendTo('body');
        dlg.dialog({
            close: function() {$('#ui-dialog').remove();},
            resizable: false,
            modal: true,
            width: 800,
            open: function() {
                if ($.ui && $.ui.dialog && $.ui.dialog.prototype._allowInteraction) {
                    var ui_dialog_interaction = $.ui.dialog.prototype._allowInteraction;
                    $.ui.dialog.prototype._allowInteraction = function(e) {
                        if ($(e.target).closest('.select2-dropdown').length) return true;
                        return ui_dialog_interaction.apply(this, arguments);
                    };
                }
            },
            _allowInteraction: function(event) {
                return !!$(event.target).is(".select2-input") || this._super(event);
            },
            create: function(event, ui) {
                addLoad();
                $.ajax({
                    async: false,
                    url: action + "/" + id + "/edit",
                    type: 'GET',
                    data: {
                        '_token': $('meta[name="csrf-token"]').attr('content')
                    },
                    error: function(xhr, status, hz) {
                        removeLoad();
                        $('#ui-dialog').dialog('close');
                        alert(common.upload_error + ':' + xhr.responseJSON.status + ' ' + xhr.responseJSON.statusText);
                        return false;
                    },
                    success: function(data, status, xhr) {
                        removeLoad();
                        $('#ui-dialog').html(data);
                    }
                });
                doDragNDrop('edit');
            },
            buttons: [{
                text: common.save,
                click: function() {
                    $('.validate-error').remove();
                    var formid = $('#ui-dialog').find('form').attr('id');
                    var action = $('#' + formid).attr('action');
                    $('#' + formid + ' :input').each(function() {
                        $(this).css('border-color', '');
                        $(this).css('border', '');
                    });
                    var formData = '_token=' + $('meta[name="csrf-token"]').attr('content');
                    formData += '&' + $('#' + formid).serialize();
                    $('#' + formid).find('.serialize').each(function() {
                        formData += '&' + $(this).sortable("serialize");
                    });
                    var form_serialize_ul_pattern = '.serialize_ul li';
                    $('#' + formid).find(form_serialize_ul_pattern).each(function() {
                        formData += '&' + $(this).attr('id') + '[]=' + $(this).contents()
                            .get(0).nodeValue.trim();
                    });

                    addLoad();
                    $.ajax({
                        url: action + '/' + id,
                        type: 'put',
                        data: formData,
                        error: function(xhr, status, hz) {
                            removeLoad();
                            if (xhr.status == 422) { // validator exception
                                validate_handler('#' + formid, xhr.responseText);
                            } else {
                                alert(common.update_error + ':' + xhr.responseJSON.status +
                                    ' ' + xhr.responseJSON.statusText);
                                $('#ui-dialog').dialog('close');
                            }
                            return false;
                        },
                        success: function(data, status, xhr) {
                            var obj = (typeof data == 'string') ? jQuery.parseJSON(data) : data;
                            if (obj.status == 'success') {
                                document.location.reload();
                            } else {
                                alert(data);
                                removeLoad();
                            }
                        }
                    });
                },
            }, {
                text: common.cancel,
                click: function() {
                    $(this).dialog('close');
                },
            }]
        });
    });

    $(document).on('click', '.str_delete', function(e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var action = $(this).attr('data-action');
        var $table = $(this).closest('table');
        $('<div id="ui-dialog" title="' + common.deleting_title + '"></div>')
        .appendTo('body')
        .html(common.deleting_question)
        .dialog({
            resizable: false,
            modal: true,
            close: function() {$('#ui-dialog').remove();},
            buttons: [{
                text: common.delete,
                click: function() {
                    var $modal = $(this);
                    addLoad();
                    $.ajax({
                        url: action + "/" + id,
                        type: "DELETE",
                        data: {
                            '_token': $('meta[name="csrf-token"]').attr('content')
                        },
                        complete: function() {
                            removeLoad();
                        },
                        error: function(xhr, status, error) {
                            $modal.dialog('close');
                            $modal.remove();
                            alert(common.upload_error + ':' + xhr.responseJSON.status + ' ' + xhr.responseJSON.statusText);
                        },
                        success: function(data, status, xhr) {
                            if (data == 'forbidden') {
                                alert(common.forbidden);
                                return;
                            }

                            var obj = (typeof data == 'string') ? jQuery.parseJSON(data) : data;

                            if (obj.status == 'success') {
                                $table.DataTable().row('#tbl_' + id).remove().draw();
                                $table.closest('.dataTables_wrapper').find('.all-check-number').prop('checked', false);
                                $table.closest('.dataTables_wrapper').find('.check-number').prop('checked', false);
                                $modal.dialog('close');
                                $modal.remove();
                            } else if (obj.status == 'error') {
                                removeLoad();
                                if (obj.msg != '') {
                                    $('#ui-dialog').html(obj.msg);
                                    $('.ui-dialog-buttonset button').first().remove();
                                    //drow_error('ui-dialog', '', obj.msg);
                                } else {
                                    drow_error('ui-dialog', 'При изменении произошла ошибка', xhr.status + ' ' + xhr.statusText);
                                }
                            } else {
                                alert(data);
                            }
                        }
                    });
                }
            },
            {
                text: common.cancel,
                click: function() {
                    $(this).dialog('close');
                    $(this).remove();
                }
            }]
        });
    });

    updateTab();
    $(".nav.nav-tabs li a").click(function(e) {
        e.preventDefault();
        history.replaceState({}, "", $(this).attr("href"));
        updateTab();
    });

    function updateTab() {
        var parent = $(".nav-tabs-custom");
        parent.find("li, .tab-content .tab-pane").removeClass("active");
        var hash = window.location.hash;
        if (hash == "") {
            hash = parent.find(".nav.nav-tabs li").eq(0).find("a").attr("href");
        }
        // console.log(hash);
        $("[href='" + hash + "']").closest("li").addClass("active");
        $(hash).addClass("active");
    }
});

function send_add(e) {
    $('.validate-error').remove();
    var formid = $('#modal-default .modal-body form').attr('id');
    var action = $('#modal-default .modal-body form').attr('action');
    $('#' + formid + ' :input').each(function() {
        $(this).parent().removeClass('has-error');
    });
    var formData = '_token=' + $('meta[name="csrf-token"]').attr('content');
    formData += '&' + $('#' + formid).serialize();
    $.ajax({
        url: action,
        data: formData,
        type: 'post',
        error: function(xhr, status, errorThrow) {
            if (xhr.status == 422) { // validator exception
                validate_handler('#' + formid, xhr.responseText, '');
            } else if(xhr.status == 409) {
                var obj = $.parseJSON(xhr.responseText);
                $('#modal-default .modal-title').text(common.save_error);
                $('#modal-default .modal-body').html(obj.statusText);
            } else {
                alert(common.save_error + ':' + xhr.status + ' ' + xhr.statusText);
            }
            return false;
        },
        success: function(data, status, xhr) {
            var obj = $.parseJSON(data);
            if (obj.status == 'success') {
                document.location.reload();
            } else if (obj.status == 'success'){
                if (obj.statusText) {
                    $('#modal-default .modal-title').text(obj.statusText);
                    $('#modal-default .modal-body').html('');
                }
            } else {
                alert(data);
            }
        }
    });
}

function send_edit(e) {
    $('.validate-error').remove();
    var formid = $('#modal-default .modal-body form').attr('id');
    var action = $('#modal-default .modal-body form').attr('action');
    let redirect = $('#modal-default .modal-body form').attr('redirect');
    $('#' + formid + ' :input').each(function() {
        $(this).parent().removeClass('has-error');
    });
    var formData = '_token=' + $('meta[name="csrf-token"]').attr('content');
    formData += '&' + $('#' + formid).serialize();
    addLoad();
    $.ajax({
        url: action,
        data: formData,
        type: 'put',
        error: function(xhr, status, errorThrow) {
            removeLoad();
            if (xhr.status == 422) { // validator exception
                validate_handler('#' + formid, xhr.responseText, '');
            } else {
                alert(common.save_error + ':' + xhr.status + ' ' + xhr.statusText);
            }
            return false;
        },
        success: function(data, status, xhr) {
            removeLoad();
            var obj = $.parseJSON(data);
            if (obj.status == 'success') {
                if(typeof(redirect) != 'undefined') {
                    if(redirect.length > 0) {
                        document.location.href = redirect;
                    } else {
                        document.location.reload();
                    }
                } else {
                    document.location.reload();
                }
            } else {
                alert(data);
            }
        }
    });
}

function clear_modal() {
    $('#modal-default .modal-body').html('');
    $('#modal-default .modal-title').text('');
}

function addLoad() {
    var text = '<div class="load"><div class="item item4"><div class="circle circle1"></div><div class="circle circle2"></div><div class="circle circle3"></div><div class="circle circle4"></div><div class="circle circle5"></div></div></div>';
    $("body").append(text);
}

function removeLoad() {
    $("body > .load").remove();
}

function ul_search(inp, elem) {
    var input, filter, ul, li, a, i, ln;
    input = document.getElementById(inp);
    filter = input.value;
    ul = document.getElementById(elem);
    li = ul.getElementsByTagName('li');

    ln = li.length;
    for (i = 0; i < ln; i++) {
        if (li[i].innerHTML.indexOf(filter) > -1) {
            li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
    }
}

function doDragNDrop() {
    $(".sortable")
        .sortable({
            revert: true,
            receive: function(event, ui) {
                $(ui.item).toggleClass('ui-state-highlight');
                if ($(ui.item).attr('param')) {
                    $(ui.item).not(':has(input)').append('<input required type="text" name="cost[]" class="darg-input form-control" id="' + $(ui.draggable).attr('param') + '" placeholder="' + $(ui.draggable).attr('text') + '" >');
                }
                $(ui.item).attr('id', $(ui.item).attr('itemid'));
                $(ui.item).not(':has(button)').append('<button type="button" class="drag_delete close"><i class="fa fa-remove drag_delete"></i></button>');
                $(ui.item).css({ height: 'auto' });
            }
        })
        .droppable({
            cursor: 'move'
        })
        .disableSelection();

    $('.draggable').each(function() {
        make_draggable($(this));
    });

    $("ul, li").disableSelection();

    $(document).on('click', 'button.drag_delete', function() {
        if ($(this).closest('.form-group').find('.draggable_ul').find('li[itemid="' + $(this).closest('li').attr('itemid') + '"]').length) {
            $(this).closest('li').remove();
        } else {
            $(this).closest('li').toggleClass('ui-state-highlight');
            $(this).closest('li').addClass('draggable');
            $(this).closest('li').attr('target', $(this).closest('ul').attr('id'));
            $(this).closest('li').appendTo($(this).closest('.form-group').find('.draggable_ul'));
            make_draggable($(this).closest('li'));
            $(this).remove();
        }
    });

}

function make_draggable(el) {
    var formid = el.closest('form').attr('id');
    var target = '#' + formid + ' ul#' + el.attr('target');
    var helper = (el.closest('ul').attr('helper')) ? el.closest('ul').attr('helper') : 'original';

    el.draggable({
        cursor: "move",
        scroll: false,
        connectToSortable: target,
        revert: "invalid",
        helper: helper
    });
}

function validate_handler(form, str, prefix) {
    prefix = prefix === undefined ? '' : prefix;
    var obj = jQuery.parseJSON(str);
    for (key in obj) {
        for (msgnum in obj[key]) {
            console.log(form, prefix);
            $(form + ' #' + key).parent().prepend('<p class="validate-error text-red">' + obj[key][msgnum] + '</p>');
            $(form + ' #' + key).focus();
            $(form + ' #' + key).parent().addClass('has-error');

            arr = key.split('.');
            if (arr[2]) {
                $(form + ' input[name="'+arr[0]+'[][\''+arr[2]+'\']"] ').eq(arr[1]).parent().prepend('<p class="validate-error text-red">' + obj[key][msgnum] + '</p>');
                $(form + ' input[name="'+arr[0]+'[][\''+arr[2]+'\']"] ').eq(arr[1]).focus();
            } else if (arr[1]) {
                $(form + ' input[name="' + arr[0] + '[]"] ').eq(arr[1]).parent().prepend('<p class="validate-error text-red">' + obj[key][msgnum] + '</p>');
                $(form + ' input[name="' + arr[0] + '[]"] ').eq(arr[1]).focus();
                $(form + ' select[name="' + arr[0] + '[]"] ').parent().addClass('has-error');
            }
        }
    }
}

/**
* Функция проверки патерна рег.выражения

 * @param string reg - патерн
 * @param string error - текст ошибки, не обязательно
 * @returns {flag|Boolean} */
function check_regexp(reg, error) {
    error = error === undefined ? false : error;
    try {
        new RegExp(reg);
        flag = false;
    } catch (err) {
        if (error) {
            alert(reg + error);
        }
        flag = true;
    };
    return flag;
}

function init_lists() {
    tag_id = $('#tag_id').val();
    list_move_right();
    list_move_left();
    list_bind_buttons();
}

function getDateFormat(microtime, format=null) {
    let newDate = new Date(microtime);
    let year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    let date = newDate.getDate();
    if(format == 'd.m.Y') {
        return ((date < 10) ? "0" + date : date) + '.' + ((month < 10) ? "0" + month : month) + '.' + year;
    } else {
        return year + "-" + ((month < 10) ? "0" + month : month) + "-" + ((date < 10) ? "0" + date : date);
    }
}

function getDateTimeFormat(microtime, format=null) {
    let newDate = new Date(microtime);
    let year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    let date = newDate.getDate();
    let hour = newDate.getHours();
    let minute = newDate.getMinutes();
    let second = newDate.getSeconds();
    if(format == 'd.m.Y H:i:s') {
        return ((date < 10) ? "0" + date : date) + '.' + ((month < 10) ? "0" + month : month) + '.' + year + ' ' + ((hour < 10) ? "0" + hour : hour) + ":" + ((minute < 10) ? "0" + minute : minute) + ":" + ((second < 10) ? "0" + second : second);
    } else {
        return year + "-" + ((month < 10) ? "0" + month : month) + "-" + ((date < 10) ? "0" + date : date) + " " + ((hour < 10) ? "0" + hour : hour) + ":" + ((minute < 10) ? "0" + minute : minute) + ":" + ((second < 10) ? "0" + second : second);
    }
}

function remove_error(id) {
    $('#' + id + ' .alert.alert-error').each(function () {
        $(this).remove();
    });
    return false;
}

/**
 * Функция вывода ошибок

 * @param string id - id формы внутри которой вывести ошибку
 * @param string head - заголовок ошибки
 * @param string text - текст ошибки
 * @param string position - куда вывести ошибку (top - в начало формы, bottom - в конец формы)
 * @param string icon - иконка favicon
 * @returns false */
function drow_error(id, head, text = '', position = 'top', icon = 'fa-close') {
    if (text != '')
        text = '<p>' + text + '</p>';
    if (head != '')
        head = '<h4><i class="icon fa ' + icon + '"></i> ' + head + '</h4>';
    else
        head = '';
    var html = '<div class="alert alert-error alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>' + head + text + '</div>';
    if (position == 'top') {
        $('#' + id).prepend(html);
    } else {
        $('#' + id).append(html);
    }
    if (typeof (id) != 'undefine') {
        var top = Number($('#' + id).offset().top);
        $('body,html').animate({scrollTop: top}, 800);
    }
    return false;
}